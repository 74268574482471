export const homeIcon = (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15.6581 16.885H9.84306C9.42906 16.885 9.09306 16.549 9.09306 16.135C9.09306 15.721 9.42906 15.385 9.84306 15.385H15.6581C16.0721 15.385 16.4081 15.721 16.4081 16.135C16.4081 16.549 16.0721 16.885 15.6581 16.885ZM19.9991 6.158C19.6361 5.838 19.2231 5.476 18.7311 5.021C18.5081 4.841 18.2641 4.635 18.0051 4.417C16.5451 3.186 14.5451 1.5 12.7221 1.5C10.9201 1.5 9.04906 3.092 7.54606 4.371C7.26806 4.607 7.00806 4.829 6.74306 5.044C6.27706 5.476 5.86406 5.839 5.50006 6.16C3.11306 8.261 2.66406 8.812 2.66406 13.713C2.66406 22.5 5.20506 22.5 12.7501 22.5C20.2941 22.5 22.8361 22.5 22.8361 13.713C22.8361 8.811 22.3871 8.26 19.9991 6.158Z'
			fill='#9E9E9E'
		/>
	</svg>
);
export const favoriteIcon = (
	<svg width='18' height='21' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<mask id='mask0_584_519' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='21'>
			<path fillRule='evenodd' clipRule='evenodd' d='M0.25 0H17.2888V20.8467H0.25V0Z' fill='white' />
		</mask>
		<g mask='url(#mask0_584_519)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.592 1.5C3.043 1.5 1.75 2.482 1.75 4.421V19.145C1.75 19.239 1.804 19.29 1.849 19.316C1.894 19.344 1.965 19.364 2.047 19.318L8.429 15.738C8.657 15.611 8.936 15.61 9.165 15.739L15.492 19.313C15.575 19.361 15.646 19.339 15.691 19.312C15.736 19.285 15.789 19.234 15.789 19.14V4.604C15.789 3.837 15.789 1.5 11.951 1.5H5.592ZM1.949 20.847C1.654 20.847 1.359 20.768 1.091 20.61C0.564 20.303 0.25 19.754 0.25 19.145V4.421C0.25 1.611 2.197 0 5.592 0H11.951C15.343 0 17.289 1.679 17.289 4.604V19.14C17.289 19.75 16.975 20.299 16.447 20.606C15.921 20.914 15.287 20.92 14.755 20.62L8.795 17.253L2.78 20.627C2.52 20.773 2.235 20.847 1.949 20.847Z'
				fill='#9E9E9E'
			/>
		</g>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.3898 8.22852H5.07178C4.65778 8.22852 4.32178 7.89252 4.32178 7.47852C4.32178 7.06452 4.65778 6.72852 5.07178 6.72852H12.3898C12.8038 6.72852 13.1398 7.06452 13.1398 7.47852C13.1398 7.89252 12.8038 8.22852 12.3898 8.22852Z'
			fill='#9E9E9E'
		/>
	</svg>
);
export const heardIcon = (
	<svg width='26.000000' height='27.000000' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<defs />
		<path
			id='Symbol'
			d='M14.82 25.05L23.86 14.4L23.86 14.4C24.43 13.73 24.88 13 25.22 12.23C25.79 10.95 26.05 9.52 25.99 7.96C25.93 6.38 25.56 4.97 24.88 3.74C24.47 2.99 23.95 2.31 23.32 1.69C22.48 0.87 21.56 0.31 20.56 0C19.74 -0.26 18.86 -0.34 17.94 -0.25C17.29 -0.19 16.67 -0.04 16.09 0.18C14.89 0.63 13.81 1.41 12.86 2.52C11.97 1.47 10.97 0.71 9.85 0.25C9.2 -0.01 8.52 -0.18 7.79 -0.25C6.85 -0.34 5.96 -0.26 5.12 0.01C4.13 0.32 3.22 0.88 2.39 1.69C1.76 2.31 1.24 2.99 0.83 3.73C0.15 4.97 -0.22 6.38 -0.28 7.96C-0.34 9.52 -0.08 10.94 0.49 12.22C0.83 13 1.28 13.72 1.85 14.4L10.89 25.05C11.17 25.39 11.49 25.64 11.85 25.79C12.15 25.93 12.49 26 12.86 26C13.22 26 13.56 25.93 13.87 25.79C14.23 25.64 14.54 25.39 14.82 25.05ZM13.9 24.28L22.95 13.63C25.56 10.54 25.42 5.42 22.48 2.54C19.92 0.04 16.14 0.52 13.76 3.29L13.65 3.43L12.88 4.36L12.08 3.45L11.95 3.29C9.62 0.52 5.79 0.04 3.23 2.54C0.29 5.42 0.15 10.54 2.76 13.63L11.81 24.28C12.37 24.98 13.34 24.98 13.9 24.28Z'
			fill='#235DFF'
			fill-opacity='1.000000'
			fill-rule='evenodd'
		/>
	</svg>
);
export const turIcon = (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.3496 21.2842C13.9356 21.2842 13.5996 20.9482 13.5996 20.5342V18.5112C13.5996 18.0962 13.9356 17.7612 14.3496 17.7612C14.7636 17.7612 15.0996 18.0962 15.0996 18.5112V20.5342C15.0996 20.9482 14.7636 21.2842 14.3496 21.2842Z'
			fill='#9E9E9E'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.3496 15.8252C13.9356 15.8252 13.5996 15.4892 13.5996 15.0752V10.2542C13.5996 9.84015 13.9356 9.50415 14.3496 9.50415C14.7636 9.50415 15.0996 9.84015 15.0996 10.2542V15.0752C15.0996 15.4892 14.7636 15.8252 14.3496 15.8252Z'
			fill='#9E9E9E'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.3496 8.171C13.9356 8.171 13.5996 7.835 13.5996 7.421V5C13.5996 4.586 13.9356 4.25 14.3496 4.25C14.7636 4.25 15.0996 4.586 15.0996 5V7.421C15.0996 7.835 14.7636 8.171 14.3496 8.171Z'
			fill='#9E9E9E'
		/>
		<mask id='mask0_243_9282' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='1' y='4' width='23' height='18'>
			<path fillRule='evenodd' clipRule='evenodd' d='M1.75 4H23.25V21.4997H1.75V4Z' fill='white' />
		</mask>
		<g mask='url(#mask0_243_9282)'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.25 15.5538V17.4928C3.25 18.8748 4.393 19.9998 5.798 19.9998H19.202C20.607 19.9998 21.75 18.8748 21.75 17.4928V15.5538C20.499 15.2248 19.573 14.0928 19.573 12.7508C19.573 11.4078 20.498 10.2768 21.75 9.94776L21.749 8.00676C21.749 6.62476 20.606 5.49976 19.201 5.49976H5.799C4.394 5.49976 3.251 6.62476 3.251 8.00676L3.25 10.0248C4.517 10.3358 5.427 11.4218 5.427 12.7508C5.427 14.0928 4.501 15.2248 3.25 15.5538ZM19.202 21.4998H5.798C3.566 21.4998 1.75 19.7018 1.75 17.4928V14.9008C1.75 14.4868 2.086 14.1508 2.5 14.1508C3.287 14.1508 3.927 13.5228 3.927 12.7508C3.927 12.0008 3.313 11.4348 2.5 11.4348C2.301 11.4348 2.11 11.3558 1.97 11.2148C1.829 11.0748 1.75 10.8828 1.75 10.6848L1.751 8.00676C1.751 5.79776 3.567 3.99976 5.799 3.99976H19.201C21.433 3.99976 23.249 5.79776 23.249 8.00676L23.25 10.6008C23.25 10.7988 23.171 10.9908 23.03 11.1308C22.89 11.2718 22.699 11.3508 22.5 11.3508C21.713 11.3508 21.073 11.9788 21.073 12.7508C21.073 13.5228 21.713 14.1508 22.5 14.1508C22.914 14.1508 23.25 14.4868 23.25 14.9008V17.4928C23.25 19.7018 21.434 21.4998 19.202 21.4998Z'
				fill='#9E9E9E'
			/>
		</g>
	</svg>
);
export const accountIcon = (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.139 12.6188H12.171C15.099 12.6188 17.48 10.2378 17.48 7.30976C17.48 4.38176 15.099 1.99976 12.171 1.99976C9.24202 1.99976 6.86002 4.38176 6.86002 7.30676C6.85002 10.2268 9.21702 12.6098 12.139 12.6188ZM8.28802 7.30976C8.28802 5.16876 10.03 3.42776 12.171 3.42776C14.311 3.42776 16.052 5.16876 16.052 7.30976C16.052 9.44976 14.311 11.1918 12.171 11.1918H12.142C10.01 11.1838 8.28102 9.44376 8.28802 7.30976Z'
			fill='#9E9E9E'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.25 18.1731C4.25 21.8701 10.212 21.8701 12.171 21.8701C15.57 21.8701 20.09 21.4891 20.09 18.1931C20.09 14.4961 14.13 14.4961 12.171 14.4961C8.771 14.4961 4.25 14.8771 4.25 18.1731ZM5.75 18.1731C5.75 16.7281 7.91 15.9961 12.171 15.9961C16.431 15.9961 18.59 16.7351 18.59 18.1931C18.59 19.6381 16.431 20.3701 12.171 20.3701C7.91 20.3701 5.75 19.6311 5.75 18.1731Z'
			fill='#9E9E9E'
		/>
	</svg>
);
