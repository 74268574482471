export const userIcon = (
	<svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10.2185 19.4999C6.86434 19.4999 4 18.978 4 16.8877C4 14.7973 6.84617 12.8677 10.2185 12.8677C13.5726 12.8677 16.4369 14.7786 16.4369 16.869C16.4369 18.9584 13.5908 19.4999 10.2185 19.4999Z'
			fill='#9E9E9E'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10.2125 9.9711C12.4137 9.9711 14.1977 8.18708 14.1977 5.98596C14.1977 3.78485 12.4137 2 10.2125 2C8.01143 2 6.22659 3.78485 6.22659 5.98596C6.21915 8.17965 7.99078 9.96367 10.1845 9.9711C10.1944 9.9711 10.2035 9.9711 10.2125 9.9711Z'
			fill='#9E9E9E'
		/>
	</svg>
);
export const phoneIcon = (
	<svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.20036 13.7993C2.11763 8.71595 2.84039 6.38822 3.37619 5.63812C3.44502 5.51688 5.14161 2.97717 6.96022 4.46721C11.4743 8.18486 5.75955 7.65924 9.55008 11.4504C13.3414 15.2408 12.8158 9.52628 16.5328 14.0394C18.0229 15.8587 15.4831 17.5553 15.3626 17.6233C14.6125 18.1599 12.2839 18.8826 7.20036 13.7993Z'
			fill='#9E9E9E'
		/>
	</svg>
);
export const lockIcon = (
	<svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.6026 6.66342V7.9412C16.0379 8.38921 17.0837 9.68853 17.0837 11.2404V15.3545C17.0837 17.2757 15.4909 18.8334 13.5272 18.8334H6.47432C4.50979 18.8334 2.91699 17.2757 2.91699 15.3545V11.2404C2.91699 9.68853 3.96362 8.38921 5.39806 7.9412V6.66342C5.40653 4.17907 7.46421 2.16675 9.98762 2.16675C12.5449 2.16675 14.6026 4.17907 14.6026 6.66342ZM10.0046 3.61595C11.7235 3.61595 13.1207 4.98234 13.1207 6.66342V7.7615H6.87993V6.64686C6.8884 4.97406 8.28559 3.61595 10.0046 3.61595ZM10.7413 14.2125C10.7413 14.6183 10.411 14.9413 9.99609 14.9413C9.58964 14.9413 9.25939 14.6183 9.25939 14.2125V12.3741C9.25939 11.9766 9.58964 11.6536 9.99609 11.6536C10.411 11.6536 10.7413 11.9766 10.7413 12.3741V14.2125Z'
			fill='#9E9E9E'
		/>
	</svg>
);
export const eyeIcon = (
	<svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.16972 13.2106C8.69011 13.5633 9.32434 13.7766 9.99923 13.7766C11.7881 13.7766 13.2436 12.3081 13.2436 10.5032C13.2436 9.82221 13.0321 9.18228 12.6825 8.6572L11.7962 9.55147C11.9426 9.83041 12.0239 10.1586 12.0239 10.5032C12.0239 11.6271 11.1132 12.546 9.99923 12.546C9.65772 12.546 9.33247 12.464 9.05601 12.3163L8.16972 13.2106ZM15.3577 5.95801C16.5367 7.04097 17.5368 8.50132 18.2849 10.257C18.3499 10.4129 18.3499 10.5934 18.2849 10.7411C16.5448 14.8268 13.4468 17.2717 9.99923 17.2717H9.9911C8.42178 17.2717 6.91752 16.7548 5.59214 15.8113L4.0147 17.4029C3.89273 17.526 3.73824 17.5834 3.58375 17.5834C3.42926 17.5834 3.26663 17.526 3.1528 17.4029C2.94952 17.1978 2.91699 16.8696 3.07962 16.6317L3.10401 16.5989L15.13 4.46484C15.1462 4.44843 15.1625 4.43202 15.1706 4.41561L15.1706 4.41561C15.1869 4.3992 15.2032 4.3828 15.2113 4.36639L15.9756 3.59519C16.2196 3.35727 16.6017 3.35727 16.8375 3.59519C17.0815 3.83311 17.0815 4.22692 16.8375 4.46484L15.3577 5.95801ZM6.74896 10.5064C6.74896 10.7197 6.77336 10.933 6.80588 11.1299L3.79735 14.1654C2.98424 13.2138 2.27683 12.0652 1.71578 10.7443C1.65073 10.5966 1.65073 10.4161 1.71578 10.2602C3.45585 6.17452 6.55381 3.73786 9.99329 3.73786H10.0014C11.1642 3.73786 12.2944 4.01681 13.3352 4.54188L10.6194 7.28209C10.4242 7.24928 10.2128 7.22466 10.0014 7.22466C8.20444 7.22466 6.74896 8.69322 6.74896 10.5064Z'
			fill='#9E9E9E'
		/>
	</svg>
);
export const notifiIcon = (
	<svg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			opacity='0.39'
			d='M12.332 24.0151H19.332C19.457 24.0151 19.582 24.1401 19.582 24.2651C19.457 26.2651 17.832 27.7651 15.832 27.7651C13.832 27.7651 12.207 26.2651 12.082 24.2651C12.082 24.1401 12.207 24.0151 12.332 24.0151Z'
			fill='#CACAD2'
		/>
		<path
			d='M24.5801 20.2656H25.8301C26.5801 20.2656 27.0801 20.7656 27.0801 21.5156C27.0801 22.2656 26.5801 22.7656 25.8301 22.7656H5.83008C5.08008 22.7656 4.58008 22.2656 4.58008 21.5156C4.58008 20.7656 5.08008 20.2656 5.83008 20.2656H7.08008L7.70508 12.7656C7.95508 9.26562 10.4551 6.51561 13.5801 5.64061C13.4551 5.39061 13.3301 5.01562 13.3301 4.64062C13.3301 3.64062 14.2051 2.76562 15.2051 2.76562H16.4551C17.4551 2.76562 18.3301 3.64062 18.3301 4.64062C18.3301 5.01562 18.2051 5.26561 18.0801 5.64061C21.2051 6.51561 23.7051 9.39062 23.9551 12.7656L24.5801 20.2656ZM21.4551 12.8906C21.2051 10.5156 19.5801 8.5156 17.3301 7.8906H17.2051C16.3301 7.6406 15.3301 7.6406 14.4551 7.8906H14.3301C12.0801 8.5156 10.4551 10.5156 10.2051 12.8906L9.58008 20.2656H22.0801L21.4551 12.8906Z'
			fill='#CACAD2'
		/>
	</svg>
);
