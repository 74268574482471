// import _ from "lodash";

const INITIAL_STATE = {
	loading: false,
	user: {},
	auth_modal: false,
	await_modal: false,
	await_data: {},
};

const reducers = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action?.payload,
			};
		case 'SET_USER':
			return {
				...state,
				user: action?.payload,
			};
		case 'SET_AUTH_MODAL':
			return {
				...state,
				auth_modal: action?.payload,
			};
		case 'SET_AWAIT_MODAL':
			return {
				...state,
				await_modal: action?.payload,
			};
		case 'AWAIT_MODAL_DATA': {
			return {
				...state,
				await_data: action?.payload,
			};
		}
		default:
			return state;
	}
};
export default reducers;
