import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { accountIcon, homeIcon } from '../../../assets/headIcons';
const MobileTabStyle = styled.div`
	display: none;
	height: 60px;
	background-color: #fff;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1000;
	border-top: 0.5px solid #235dff;
	justify-content: space-around;
	@media (max-width: 900px) {
		display: flex;
	}
	& .link {
		cursor: pointer;

		& .icon {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		& .title {
			color: #9e9e9e;
			font-size: 10px;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0.2px;
			text-align: center;
		}
		&:hover,
		&.active {
			& .icon {
				& path {
					fill: #235dff;
				}
			}
			& .title {
				font-weight: 700;
				color: #235dff;
			}
		}
	}
`;
const MobileTab = () => {
	const dispatch = useDispatch();
	const { user } = useSelector(s => s);
	return (
		<MobileTabStyle className='ds_flex'>
			<NavLink className='link' to='/'>
				<div className='icon'>{homeIcon}</div>
				<div className='title'>Главная</div>
			</NavLink>
			{/* <NavLink className='link' to="#">
        <div className="icon">{favoriteIcon}</div>
        <div className="title">Избранные</div>
      </NavLink> */}
			{/* <NavLink className='link' to="#">
        <div className="icon">{turIcon}</div>
        <div className="title">Горящие туры</div>
      </NavLink> */}
			{user?.id ? (
				<NavLink className='link' to='/my-apps'>
					<div className='icon'>X</div>
					<div className='title'>Мои заявки</div>
				</NavLink>
			) : (
				<div
					className='link'
					style={{
						cursor: 'pointer',
					}}
					onClick={() => {
						dispatch({ type: 'SET_AUTH_MODAL', payload: true });
					}}
				>
					<div className='icon'>X</div>
					<div className='title'>Мои заявки</div>
				</div>
			)}

			{user?.id ? (
				<NavLink className='link' to='/profile'>
					<div className='icon'>{accountIcon}</div>
					<div className='title'>Аккаунт</div>
				</NavLink>
			) : (
				<div
					className='link'
					onClick={() => {
						dispatch({ type: 'SET_AUTH_MODAL', payload: true });
					}}
				>
					<div className='icon'>{accountIcon}</div>
					<div className='title'>Войти</div>
				</div>
			)}
		</MobileTabStyle>
	);
};

export default MobileTab;
