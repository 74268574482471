import styled from 'styled-components';

export const Btn = styled.button`
	background: #235dff;
	width: 100%;
	color: #fff;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: center;
	border-radius: 12px;
	height: 60px;
	opacity: 0.9;
	@media (max-width: 900px) {
		font-size: 18px;
		font-weight: 700;
		line-height: 32px;
		letter-spacing: 0em;
	}
	&:hover {
		opacity: 1;
	}
`;

export const BtnAwait = styled.button`
	background: #fbbc05;
	width: 100%;
	color: #fff;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
	letter-spacing: 0em;
	text-align: center;
	border-radius: 12px;
	height: 60px;
	opacity: 0.9;
	@media (max-width: 900px) {
		font-size: 18px;
		font-weight: 700;
		line-height: 32px;
		letter-spacing: 0em;
	}
	&:hover {
		opacity: 1;
	}
`;
